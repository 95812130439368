<template>
  <BudgetsList />
</template>

<script>
  export default {
    components: {
      BudgetsList: () => import('@/components/comercial/budgets/BudgetsList')
    }
  }
</script>